 @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");
:root {
	--primary: #fac710;
	--secondary: #111;
	--tribute: #e5e1e1;
	--cookies: #fbf6e4;
	--textColor: #808080;
}
.header{
	padding-top: 2rem !important;
	color: #111;
}
.coloured-text{
	color: var(--primary);
}
.container{
	max-width: 1100px;
	margin: 0 auto;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	overflow-x: hidden;
	font-family: "Lato", sans-serif;
	color: var(--secondary);
}

/* General  Materialize */
.btn {
	border-radius: 10px;
	background: var(--secondary);
}
.btn:hover {
	background-color: var(--primary);
	color: var(--cookies);
}
.btn:focus {
	background: var(--secondary);
}
.btn:active {
	transform: scale(0.98);
	background: var(--primary);
}
.nav-btn:hover,
.nav-btn > a:hover {
	background: var(--primary) !important;
}
.dropdown-link {
	color: white !important;
}
.navbar-links:hover {
	color: var(--primary) !important;
}
.navbar-dropdown {
	width: 220px !important;
}
.navbar-dropdown a:hover {
	color: var(--primary) !important;
}
.side-menu,
.sidenav,
.collapsible-body {
	background-color: #111 !important;
}
/* ==========Scroll bar  ==============*/
::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	background: var(--secondary);
}
::-webkit-scrollbar-thumb {
	background: var(--primary);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: var(--primary);
}
/* ==========Scroll bar ============== */

/* ==========Check box ============== */
.labelcheck {
	width: 100px;
	height: 100px;
	font-size: 1.5rem;
}
label,
input[type="checkbox"] {
	line-height: 2.1ex;
	width: 2rem;
	height: 2rem;
}
input[type="checkbox"] {
	position: absolute;
	left: -999em;
}
input[type="checkbox"] + label {
	position: relative;
	overflow: hidden;
	cursor: pointer;
}
input[type="checkbox"] + label::before {
	content: "";
	display: inline-block;
	vertical-align: -25%;
	height: 2ex;
	width: 2ex;
	background-color: white;
	border: 1px solid #fbf6e4;
	border-radius: 4px;
	box-shadow: inset 0 2px 5px #fbf6e4;
	margin-right: 0.5em;
}
input[type="checkbox"]:checked + label::after {
	content: "";
	position: absolute;
	width: 1.3ex;
	height: 0.6ex;
	background: #fbf6e4;
	top: 1.1ex;
	left: 0.4ex;
	border: 3px solid #fac710;
	border-top: none;
	border-right: none;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
/* =============Social media navbar =========== */
.social-media-navbar {
	background: var(--tribute);
	margin-bottom: -20px;
}
.social-media-navbar > div > div > div > a > img {
	width: 2.5rem;
	height: 2.4rem;
	padding: 0.05rem;
}
/* ======== Navigation bar Styles / Drop down ======= */
.nav-wrapper,
.nav-wrapper li > a {
	background-color: var(--secondary) !important;
}
.logo {
	width: 50px;
	height: 50px;
	padding: .5rem;
	border: 2px solid var(--primary);
	border-radius: 50%;
	margin-left: 2rem;
	margin-top: .5rem;
}
.nav-btn-small {
	margin-top: 10px;
	border-radius: 0.5rem;
	border: 1px solid var(--primary);
	margin-right: 0.3rem;
	font-size: 0.7rem;
}
.nav-btn-large {
	margin-top: 5px !important;
	border-radius: 0.5rem;
	border: 1px solid var(--primary);
	margin-right: 0.3rem;
}
/* Navbar links */
.navbar-links {
	text-transform: uppercase;
	font-weight: bold;
}
.navbar-links > li > a:hover {
	color: var(--primary);
}
.navbar-dropdown {
	border-radius: 10px;
	padding-top: 0.5rem;
	background: var(--secondary);
	border-bottom: 0.1rem solid var(--primary);
}
.dropdown-link:hover {
	color: var(--primary);
}
/* side-menu */
.collapsible-body > a {
	padding-left: 20px;
	color: white;
}
.accordion-submenu:hover {
	color: var(--primary);
}
/* Navbar roll down on scroll */
.roll-down-nav {
	transition: all 0.5s;
	height: 50px;
	background-color: var(--secondary);
	position: fixed;
	color: #fff;
	top: -500px;
	width: 100%;
	z-index: 10;
}
.roll-down-nav.showNav {
	top: 0;
}

/* ============ CTA Section ===================== */
.cta {
	height: auto;
}
.cta-container {
	position: relative;
	height: 100%;
	width: 100%;
	/* background-color: #fff; */
	/* opacity: 0.7; */
}
.cta-illustration{
	position: relative;
	height: 80%;
}
.placeholder-div{
	/* padding: 1rem ; */
	/* position: relative; */
	width: 100%;
	height: 700px;
	background-image: url("../../static/images/cta/undraw_Home_settings_re_pkya.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	/* background-color: aqua; */
}
.placeholder-div button{
	max-width: 120px;
	position: absolute;
	left: 40.5%;
	bottom: 15%;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.2;
	text-align: center;
	background: #FAC710;
	border-radius: 5px;
	border: none;
	padding: .3rem;
}

.cta-header{
	line-height: 1.2;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 800px;
	margin: 1rem auto;
	text-align: center;
	font-weight: bolder;
	font-size: 2.7rem;
}
.quote{
	position: absolute;
	width: 200px;
	height: 200px;
	left: 63%;
	top: 5rem;
}
.oval{
	position: absolute;
	height:70vh;
	width: 5rem;
	background-color: transparent !important;
}
.oval.dark{
	top: -5rem;
}
.oval.yellow{
	bottom: -10rem;
	right: 0;
}


/* =================== Floating button to top  ================= */
.top-btn {
	width: 50px;
	height: 50px;
	background: var(--cookies);
	color: var(--secondary);
	text-align: center;
	border-radius: 10px;
	position: fixed;
	bottom: 10px;
	right: 30px;
	cursor: pointer;
	line-height: 50px;
	z-index: 999;
	transition: 0.5s;
	font-size: 2rem;
	visibility: hidden;
}
.top-btn i {
	font-weight: bolder;
}
.top-btn.show {
	visibility: visible;
}

/* =======================  footer  ================= */
.footer {
	background: var(--secondary);
	/* background-color: #FAC710; */
	margin-top: 7rem;
	font-size: 1.5rem;
	color: var(--textColor);
}
.sub-footer-inner {
	padding: 1rem 1.5rem;
	display: grid;
	grid-template-columns: 1fr 5fr;
}

.sub-footer{
	position: relative;
}
#dark_vector {
	position: absolute;
	width: 100%;
	top: -7rem;
	left: 0;
	right: 0;
	height: 200px;
	z-index: -1;
	margin: 0 auto;

}
.footer-logo {
	width: 8.75rem;
}
.footer-text-heading {
	text-align: right;
}
.footer-text-heading > span {
	padding: 0.5rem;
}
/* Footet content section one / Qualities */
.footer-grid{
	position: relative;
	padding: 2rem 0;
	max-width: 800px;
	margin: 0 auto;
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(2,1fr);
	grid-template-rows: repeat(2,1fr);
}
.footer-grid .box{
	align-self: center;
	justify-self: center;
	height: 300px;
	width: 300px;

}
.win-tag{
	position: absolute;
	left: 0;
	right: 0;
	top: 47%;
	background-color: #fac710;
	padding: 1rem;
	border-radius: 20px;
	max-width: 200px;
	margin: 0 auto;
	color: #000000;
	font-weight: 600;
}
.sub-footer .header {
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 600px;
	margin: 1rem auto;
	text-align: center;
	font-weight: bolder;
	font-size: 2.7rem;
	color: #fff;
}
.sub-footer .logo{
	width: 50px;
	height: 50px;
	padding: .5rem;
	border: 2px solid var(--primary);
	border-radius: 50%;
	left: 2rem;
	position: absolute;
	margin: 0;
	margin-top: 2.5rem;
}
.subcribe-tag .container{
	/* padding: 0rem 2rem; */
	max-width: 500px;
	margin: 2rem auto;
	/* height: 50px; */
	display: grid;
	grid-template-columns: repeat(2,1fr);
	background-color: #D9D9D9;
	border: 2px solid var(--primary);
}
.subcribe-tag .container .text{
	height: 100%;
	background: url('../../static/images/Group\ 563.png');
	background-position: left;
	background-size: contain;
	background-repeat: no-repeat;
}
.subcribe-tag .container .input-btn{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 .5rem;
	/* border-left: 2px solid var(--primary); */
}
.subcribe-tag .container .input-btn input{
	/* max-width: 200px; */
	color: #000000;
	border: none;
	font-size: large;
}
::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #000000;
	opacity: 1;
	/* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #000000;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #000000;
}
.go{
	background-color: #000000;
	color: #fff;
	padding: .2rem;
	border-radius: 5px;
	margin-left: .5rem;
}

/* Footet content section one / Verifed agent */


.follow-us{
	border: 2px solid var(--textColor);
	border-left: none;
	border-right: none;
	padding: 2rem ;
	/* background-color: #ffffff; */
}
.follow-us .subfoot-brand{
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: var(--tribute);
	/* background-color: #808080; */
	padding: 0 1rem;
	
}
.subfoot-brand .social-icon{
	width: 30px;
	height: 30px;
}

.sub-footer-content2 {
	margin: 50px auto;
	padding: 0 3%;
}
.verified {
	display: flex;
	align-items: center;
}
.agent-text {
	flex: 0.3;
	padding-right: 1rem;
	height: 50%;
}
.agent-logos {
	align-items: center;
	flex: 2;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	border-left: 0.5rem solid var(--primary);
	padding-left: 1rem;
}
.verified img {
	width: 70%;
	padding: 0.6rem;
}



/* =================== sponsor ===================== */
.sponsor{
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.sponsor img{
	width: 50px;
}

/* =================== solution ===================== */
.solution{
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: #E5E1E1;
	color: #111;
	font-weight: 600;
}
.solu1{
	display: flex;
	align-items: center;
	justify-content: center;
}
.solu1 img{
	padding: .5rem;
	width: 45px;
	height: 45px;
	margin: 1rem;
	background-color: #111;
	border: 5px solid #fff;
}
.solu-res{
	display: flex;
	align-content: center;
	justify-content: center;
}
.solu-res img{
	width: 20px;
	height: 20px;
}
/* =================== Tribute ===================== */
.tribute {
	background: var(--tribute);
	font-size: 1rem;
	color: var(--textColor);
	border: none;
}
.tribute-2 {
	margin-top: -2px;
}

/* ============ Cookies ================== */
.cookies-banner {
	position: fixed;
	transition-duration: 150ms;
	bottom: -500px;
	width: 100%;
	color: var(--textColor);
	z-index: 5;
	background: var(--primary);
	font-size: large;
	border-radius: 10px;
	text-align: center;
}
.cookie-banner-text {
	padding: 1rem 1rem 0.3rem;
	width: 75%;
	margin: 0.1rem auto 0;
}
.showBanner {
	display: block;
	bottom: 0;
}
.accept-cookies {
	background: var(--cookies);
	color: var(--textColor) !important;
	text-transform: capitalize;
	margin-bottom: 20px;
	border-radius: 10px;
}
.accept-cookies:hover {
	background: var(--primary);
}
.check-box span {
	color: var(--cookies);
}
/* ============ media queries ============== */
@media screen and (max-width: 768px) {
	/* Cta */
	.cta-text {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2.5rem;
	}
	/* Footer */
	.sub-footer-content1 {
		grid-template-columns: repeat(1, 1fr);
	}
	.agent-text {
		flex: 1.5;
	}
	.agent-logos {
		flex: 2;
	}
	.verified img {
		width: 100%;
	}
	.footer-logo-container {
		text-align: center;
	}
	.footer-text-heading {
		text-align: center;
	}
	.sub-footer-inner {
		grid-template-columns: 1fr;
		text-align: center;
	}
	.last-footer-text {
		display: inline-block;
		width: 100%;
	}
	.logo {
		width: 60%;
		margin-top: 15px;
		margin-left: 15%;
	}
	.sub-btn-1 {
		margin: 0.5rem;
	}
	/* Cookies */
	.cookie-banner-text {
		width: 100%;
	}
}
