/* ====== Carousel ========= */
.btn-carousel-sm {
	font-size: 0.7rem;
}
/* Slide 1  */
.carousel-item {
	position: relative;
	z-index: 1;
}
.carousel-item {
	border: none;
}
.carousel-item a {
	margin: 10px 12%;
}
.carousel-text {
	width: 35%;
	position: absolute;
	z-index: 2;
	top: 15%;
	left: 58%;
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 3.5rem;
}
/* Slide 2 */
.carousel-item1 {
	text-align: right;
}
.carousel-text1 {
	text-align: left;
	top: 15%;
	left: 14%;
}
/* Slide 3 */
.carousel-text2 {
	text-align: left;
	top: 10%;
	left: 14%;
}
/* Slide 4 */
.carousel-item3 {
	text-align: right;
}
.carousel-text3 {
	text-align: left;
	top: 10% !important;
	left: 35%;
}
/* ====Splide js customised */
/* .splide__pagination {
	display: flex;
	flex-direction: column;
	bottom: 50%;
	left: 98%;
	transform: translate(0, 50%);
	width: 10px;
	justify-content: space-around;
} */
/* .splide__pagination .splide__pagination__page {
	width: 5px;
	height: 4rem;
}
.splide__pagination__page {
	display: inline-block;
	width: 20px;
	height: 5px;
	background: var(--textColor);
	border-radius: 0;
	margin: 10px;
	padding: 0;
	transition: all 0.2s linear;
	border: none;
} */
/* .splide__pagination__page.is-active {
	background: var(--primary);
	transform: scale(1);
}
.splide__pagination__page:hover {
	cursor: pointer;
	background: var(--primary);
}
.splide__pagination__page:focus {
	outline: none;
} */
/* ============== */
@media (min-width: 960px) {
	.carousel-item {
		height: 100%;
		width: 100%;
	}
	.splide__pagination {
		left: 94%;
	}
	.splide__pagination .splide__pagination__page {
		width: 5px;
		height: 7rem !important;
	}
	.splide__pagination {
		left: 94%;
	}
	.splide__pagination .splide__pagination__page {
		width: 5px;
		height: 3.5rem;
	}
}

@media (max-width: 645px) {
	.carousel-item a {
		margin: 10px auto;
		border: none;
	}
	.carousel-text {
		top: 10%;
		font-size: 1rem;
	}
	.carousel-text1 {
		top: 10%;
		font-size: 1rem;
	}
	.carousel-text2 {
		top: 10%;
	}
	.carousel-text3 {
		top: 7%;
	}
	.find-out-more {
		color: #ffffff;
		letter-spacing: 1px;
		background-color: #111111;
		text-transform: capitalize;
		margin-bottom: 20px;
		border-radius: 10px;
	}
	.splide {
		height: 350px;
		overflow: hidden;
	}
	.splide__pagination {
		left: 93%;
	}
	.splide__pagination .splide__pagination__page {
		width: 5px;
		height: 2.5rem;
	}
}
@media (max-width: 400px) {
	.carousel-text {
		font-size: 0.8rem;
	}
	.splide {
		height: 200px;
		overflow: hidden;
	}
	.splide__pagination {
		left: 90%;
	}
	.splide__pagination .splide__pagination__page {
		width: 5px;
		height: 1.5rem;
	}
}
