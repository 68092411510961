.about-our-services {
	min-height: 100vh;
}
.our-services-head {
	text-align: center;
}
.our-services-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.our-services-item {
	position: relative;
	width: 350px;
	border: 3px solid #fac710;
	padding: 1rem;
}
.services-counter {
	display: absolute;
	height: 30px;
	text-align: center;
	width: 30px;
	margin-top: -30px;
	margin-left: -30px;
	background: var(--primary);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
