/* =========magnnifying glass */
.fun-bg {
  position: relative;
  background: var(--cookies);
  padding-bottom: 3rem;
  margin-top: 10rem;
}
.funfacts-heading {
  text-align: center;
  padding-top: 2rem;
  font-weight: bold;
}
.funfacts {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  justify-items: center;
  align-items: center;
  overflow: visible;
}
.funfacts-left-text > h3 {
  margin: 3rem;
  font-size: 4rem;
}
.funfacts-center {
  display: inline-block;
  border: 0.1em solid #fac710;
  position: relative;
  border-radius: 50%;
  border: 2rem solid var(--primary);
  background: var(--tribute);
  border-radius: 50%;
  text-align: center;
  padding-top: 7%;
}
.funfacts-center::before {
  font-size: 30em;
  content: "";
  display: inline-block;
  position: absolute;
  right: -0.17em;
  bottom: -0.001em;
  border: 0.01em dashed #fac710;
  width: 0.4em;
  height: 0.06em;
  z-index: -1;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
}
.funfacts-right-text {
  margin: 4rem 2rem 2rem;
  font-size: 1.5rem;
  text-align: center;
}
/* ====== Media Queries ====== */
@media only screen and (min-width: 997px) {
  .funfacts-center {
    margin: 3rem;
    text-align: center;
    width: 400px;
    height: 400px;
  }
}
@media only screen and (max-width: 996px) {
  .funfacts {
    grid-template-columns: 1fr;
  }
  .funfacts-center {
    width: 500px;
    height: 500px;
  }
}
@media only screen and (max-width: 768px) {
  .funfacts-container {
    height: 790px !important;
  }
  .fun-facts {
    height: 1000px;
  }
  .funfacts-center {
    width: 430px;
    height: 430px;
  }
}
@media only screen and (max-width: 530px) {
  .funfacts-container {
    height: 740px !important;
  }
  .funfacts {
    grid-template-columns: 1fr;
  }
  .funfacts-center {
    width: 315px;
    height: 315px;
  }
}
@media only screen and (max-width: 420px) {
  .funfacts {
    grid-template-columns: 1fr;
    padding: 2rem 0;
  }
  .funfacts-center {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (max-width: 300px) {
  .funfacts {
    grid-template-columns: 1fr;
    padding: 1rem 0;
  }
  .funfacts-center {
    width: 130px;
    height: 130px;
  }
}
